




























import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiPencil} from '@mdi/js';
import {Prop} from 'vue-property-decorator';
import {Size} from '@/enums';

@Component
export default class ButtonEditAction extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: false}) private loading!: boolean;
  @Prop({default: Size.Small}) private size!: Size;
  @Prop({default: null}) private to!: string | null;
  private iconEdit = mdiPencil;
  private sizeExtraSmall = Size.ExtraSmall;
  private sizeSmall = Size.Small;
  private sizeMedium = Size.Medium;
  private sizeLarge = Size.Large;
  private sizeExtraLarge = Size.ExtraLarge;
}
